/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header/header"
import Footer from "./footer/footer";
import "./layout.scss";

import { ThemeProvider } from 'emotion-theming'
import theme from '@rebass/preset'

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
     <ThemeProvider theme={theme}>
     <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main style={{minHeight: '90vh'}}>{children}</main>
        <Footer/>
      </div>
  </ThemeProvider>
  
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
