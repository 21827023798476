import React from "react"
import { Link } from "gatsby"
 const CustomLink = props => (
  <Link
  {...props}
  style={{
      'color': 'inherit',
      'textDecoration': 'none'
  }}
  > { props.children } </Link>
)

export default CustomLink;
