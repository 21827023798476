import Link from '../shared/link';
import PropTypes from "prop-types"
import React from "react"
import { Flex, Text, Box } from "rebass"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toggled: false,
    }
  }
  setToggleMenu = () => {}
  render() {
    const { siteTitle } = this.props

    return (
      <nav className="c-global-navigation-section">
        <Flex px={2} alignItems="center">
          <Link style={{textDecoration: 'none', color: 'inherit'}} to="/">
            <Text p={2} fontWeight="bold">
              Brimudi
            </Text>
          </Link>
          <Box mx="auto" />
          <Link variant="nav" to="/brands">
            Brands
          </Link>
        </Flex>
      </nav>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
