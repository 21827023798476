import PropTypes from "prop-types"
import React from "react"
import { Flex, Text, Box } from "rebass"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer>
    <Flex px={2} bg="black" alignItems="center">
      <Box mx="auto" />
      <Text> © 2019 by Brimudi</Text>
      <Link variant="nav" to="/">
        Profile
      </Link>
    </Flex>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
